import React, { useMemo, useEffect, useState } from "react";
import {
    HomeIcon,
    ArrowLeftEndOnRectangleIcon,
} from "@heroicons/react/24/outline"; // Importing Hero Icons
import ReactWebChat, { createDirectLine, createStyleSet } from "botframework-webchat";
import API from "./core/api";
import Header from "./Header";
import Toolbar from "./Toolbar";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
var md = require('markdown-it')();

const WebChat = () => {
    const [directLineToken, setDirectLineToken] = useState("");
    const [userId, setUserId] = useState("");
    const [directLine, setDirectLine] = useState(null);
    const [directLineError, setDirectLineError] = useState(null);
    const [loggedOutStatus, setLoggedOutStatus] = useState("init");

    const styleOptions = {
        botAvatarImage: 'mda-logo',
        botAvatarInitials: 'MDA',
        // userAvatarImage: '<URL to your user avatar image>',
        userAvatarInitials: 'SP',
        markdownRenderHTML: true
    };

    // Define the data and handlers
    const toolbarItems = [
        {
            label: 'Start over',
            icon: ArrowPathIcon,
            onClick: () => {
                localStorage.setItem("directLineToken", '');
                window.location.reload();
            },
        }
    ];

    const styleSet = createStyleSet({
        bubbleBackground: 'rgba(255, 255, 255, 1)',
        botAvatarBackgroundColor: 'rgba(255, 255, 255, 1)',
        bubbleMaxWidth: '100%',
        bubbleBorderWidth: '1px',
        bubbleFromUserBorderWidth: '2px'

        // rootHeight: '100%',
        // rootWidth: '100%',
        // backgroundColor: 'white'
    });

      // After generated, you can modify the CSS rules.
      // Change font family and weight. 
      styleSet.textContent = {
        ...styleSet.textContent,
        fontFamily: "'Calibri','Helvetica Neue','Arial','sans-serif'",
        fontSize: '85%'
     };

    useEffect(() => {
        if(loggedOutStatus === 'loggedOut') {
            window.location.assign("https://mydatamazing.com/login"); // Redirect to login page
        }
    }, [loggedOutStatus]);

    useEffect(() => {
        const handleLogoutEvent = (e) => {
          if (e.key === 'logout') {
            window.location.assign("https://mydatamazing.com/login"); // Redirect to login page
          }
        };
    
        // Add event listener for storage changes
        window.addEventListener('storage', handleLogoutEvent);
    
        return () => {
          // Clean up the event listener
          window.removeEventListener('storage', handleLogoutEvent);
        };
      }, []);

    const handleLogout = async () => {

        // Call API.logout
        const result = await API.logout();
        if (result.success) {
            // Handle successful logout
            setLoggedOutStatus('loggedOut');
            localStorage.setItem('logout', Date.now());
        } else {
            // TODO: Improve here
        }

    };

    const fetchDirectLineTokenAndUserID = async () => {
        const storedToken = localStorage.getItem("directLineToken");
        // Call API.register
        const result = await API.getDirectLineToken({
            token: storedToken || "",
        });
        if (result.success) {
            const { token, userId } = result["data"];
            localStorage.setItem("directLineToken", token);
            setDirectLineToken(token);
            setDirectLine(createDirectLine({ token: token }));
            setUserId(userId);
            setDirectLineError(null);
        } else {
            console.error("Error fetching DirectLine token and userID:", result["success"]);
            setDirectLineToken("");
            setDirectLine(null);
            setUserId("");
            setDirectLineError(result.status);
        }
    };

    useEffect(() => {
        fetchDirectLineTokenAndUserID();
    }, []);

    const accountItems = [
        {
            name: "Account",
            handleAccountItemClick: () => {
                window.location.assign('https://mydatamazing.com/dashboard');
            },
            icon: <HomeIcon className="h-5 w-5 mr-2" />,
        },
        {
            name: "Logout",
            handleAccountItemClick: async () => {
                await handleLogout();
            },
            icon: <ArrowLeftEndOnRectangleIcon className="h-5 w-5 mr-2" />,
        },
    ].filter(element => element !== null);

    // Create a custom Markdown render function for links
    const customRenderMarkdown = (text) => {
        // Use a custom renderer to change how links are handled
        const originalRender = md.render.bind(md);
        md.renderer.rules.link_open = (tokens, idx, options, env, self) => {
        // Add target="_blank" to open links in a new tab
        tokens[idx].attrPush(['target', '_blank']);
        // Add class to make links bold
        tokens[idx].attrPush(['class', 'font-bold']);
        return self.renderToken(tokens, idx, options);
        };
        return originalRender(text);
    };

    return (

        <div className="flex flex-col h-screen">
            <Header authLinks={[]} accountItems={accountItems} />
            <div className="flex-1 flex flex-col items-center justify-center">
                <div className="border border-indigo-400 w-full md:w-3/4 lg:w-full max-w-full h-[85vh] bg-white overflow-hidden">
                    {directLineToken ? (
                        <div className="flex flex-col h-[100%]">
                            <div>
                                <Toolbar  items={toolbarItems}></Toolbar>
                            </div>
                            <div className="scrollbar flex-1 p-2 overflow-hidden custom-chat">
                                <ReactWebChat
                                    directLine={directLine}
                                    userID={userId}
                                    styleOptions={styleOptions}
                                    styleSet={styleSet}
                                    renderMarkdown={ customRenderMarkdown }
                                />
                            </div>
                        </div>
                    ) : (
                        directLineError == 403 ?
                        (<div className="flex flex-col items-center justify-center h-full">
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Session expired
                    </h2>
                            <a href="https://mydatamazing.com/login">
                                <p className="text-center text-2xl font-bold leading-9 tracking-tight text-blue-600">Login</p>
                            </a>
                        </div>) :
                        (<div className="flex items-center justify-center h-full">
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Loading chat...
                    </h2>
                        </div>)
                    )}
                </div>
            </div>
        </div>
    );
};

export default WebChat;
