import React from "react";

// Toolbar component
const Toolbar = ({ items }) => {
    return (
        <div className="flex w-[100%] items-end">
            {items.map((item, index) => (
                <button
                    key={index}
                    onClick={item.onClick}
                    className="flex items-center p-2 ml-4 text-gray-700 hover:bg-gray-200 rounded"
                >
                    <item.icon className="w-6 h-6" />
                    <span className="ml-2">{item.label}</span>
                </button>
            ))}
        </div>
    );
};


export default Toolbar;
